import React from 'react'

function Cart() {
  return (
    <div>
      <h1>Cart</h1>
      
    </div>
  )
}

export default Cart
